import * as yup from 'yup'
import { customFieldsSchema } from '@/schema/customFields'

export const inviteUserSchema = () => yup.object().shape(
  {
    firstName: yup.string().required().nullable().max(50, 'Max 50 limit'),
    lastName: yup.string().required().nullable().max(50, 'Max 50 limit'),
    mobileNumber: yup.string().required().nullable().max(50, 'Max 50 limit'),
    email: yup.string().required().nullable().max(50, 'Max 50 limit'),
    roleId: yup.string().required().nullable(),
  },
)

export default {
  inviteUserSchema,
}
