<template>
  <div>
    <invite-user-form
      :visible="showForm"
      :resource-id="resourceId"
      @saved="getInitialData()"
      @close="onCloseForm"
    />
    <prozess-resource-view
      :resource="user"
      :loading="loading"
      :fields="fields"
      :can-update="$can('PerformAs', 'CompanyAdmin')"
      @edit="edit()"
    >
      <template slot="additional-buttons">
        <b-dropdown
          v-if="user.status === 'Invited'"
          :text="$t('Actions')"
          variant="outline-secondary"
        >
          <b-dropdown-item @click="sendInvitation">{{
            $t('Send invitation again')
          }}</b-dropdown-item>
        </b-dropdown>
      </template>

      <template slot="body">
        <b-tabs content-class="mt-1">
          <b-tab active>
            <template #title>
              <feather-icon icon="ListIcon" /> {{ $t('History') }}
            </template>
            <b-overlay
              :show="loading"
              spinner-variant="primary"
              rounded="sm"
              variant="transparent"
              style="height: 100%"
              class="d-flex flex-column"
            >
              <div class="card">
                <div class="card-body mb-5">
                  <prozess-resource-history
                    :history="items"
                    :loading="loading"
                    :history-pagination="{
                      currentPage,
                      total,
                      perPage,
                    }"
                    @handleCurrentPage="handleCurrentPage"
                  />
                </div>
              </div>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </template>
      <template slot="topDetails">
        <div class="d-flex mb-3">
          <img class="mr-2 user-view__avatar" :src="dummyAvatar" alt="Avatar" />
          <div style="flex: 1" class="d-flex">
            <div class="d-flex flex-column justify-content-around mr-1">
              <span class="user-view__label">{{ $t('Firstname') }}</span>
              <span class="user-view__label">{{ $t('Lastname') }}</span>
            </div>
            <div class="d-flex flex-column justify-content-around">
              <span class="user-view__field-value-name">{{
                user.firstName
              }}</span>
              <span class="user-view__field-value-name">{{
                user.lastName
              }}</span>
            </div>
          </div>
        </div>
      </template>
    </prozess-resource-view>
  </div>
</template>

<script>
import employeeService from '@/services/employee'
import dummyAvatar from '@/assets/images/avatars/dummy-avatar.jpg'
import userStatusBadge from '@/constants/userStatusBadge'
import RootEvents from '@/constants/rootEvents'
import InviteUserForm from './Form.vue'
import listMixinFactory from '@/mixins/list'

export default {
  components: {
    InviteUserForm,
  },
  mixins: [
    listMixinFactory({
      service: employeeService,
      isHistory: true,
    }),
  ],
  data() {
    return {
      userStatusBadge,
      dummyAvatar,
      user: {},
      userHistory: [],
      loading: true,
      fields: {
        jobTitle: 'JobTitle',
        email: 'Email',
        mobileNumber: 'Mobile',
        groups: 'Groups',
        roleName: 'Role',
        status: 'Status',
      },
      resourceId: null,
      showForm: false,
    }
  },
  mounted() {
    this.getInitialData()
  },
  methods: {
    async sendInvitation() {
      this.$swal.fire({
        title: this.$t('Sending invitation...'),
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      const { error } = await this.$async(
        employeeService.resendInvitation(this.$route.params.id),
      )

      if (!error) {
        this.$swal.hideLoading()
        this.$swal({
          title: this.$t('Success'),
          text: this.$t('Invitation successfully sent.'),
          icon: 'success',
        })
      }
    },
    getInitialData() {
      employeeService
        .getOne(this.$route.params.id)
        .then(res => {
          this.user = res.data
          this.$root.$emit(
            RootEvents.SET_PAGE_TITLE,
            `${this.user.firstName} ${this.user.lastName}`,
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    edit() {
      this.showForm = true
      this.resourceId = this.$route.params.id
    },
    onCloseForm() {
      this.showForm = false
      this.resourceId = null
    },
    action(value) {
      const actions = {
        U: 'updated',
        D: 'removed',
        T: 'truncated',
        I: 'created',
      }
      return actions[value] || ''
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/user-view.scss';
</style>
