<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    form-icon="PlusIcon"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    :save-icon="saveIcon"
    :save-label="saveLabel"
    @close="close"
    @submit="save"
  >
    <form
      id="formSidebar"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <prozess-input
        v-model="resource.firstName"
        :placeholder="$t('First Name')"
        field="firstName"
        icon="UserIcon"
        :error="$hasError('firstName')"
        @enter="save"
      />
      <prozess-input
        v-model="resource.lastName"
        :placeholder="$t('Last Name')"
        field="lastName"
        icon="UserIcon"
        :error="$hasError('lastName')"
        @enter="save"
      />
      <prozess-input
        v-model="resource.jobTitle"
        :placeholder="$t('Job Title')"
        field="jobTitle"
        icon="BriefcaseIcon"
        :error="$hasError('jobTitle')"
        @enter="save"
      />
      <prozess-input
        v-model="resource.email"
        :placeholder="$t('Email')"
        field="email"
        icon="MailIcon"
        :error="$hasError('email')"
        @enter="save"
      />
      <prozess-input
        v-model="resource.mobileNumber"
        :placeholder="$t('Mobile')"
        field="mobileNumber"
        icon="SmartphoneIcon"
        :error="$hasError('mobileNumber')"
        @enter="save"
      />
      <b-input-group
        class="input-group-merge tw-pb-3 mt-1 mb-2 form-sidebar__add-user"
      >
        <b-input-group-prepend is-text>
          <feather-icon icon="UsersIcon" />
        </b-input-group-prepend>
        <prozess-select
          v-model="resource.groupIds"
          style="flex: 1"
          :placeholder="$t('Groups')"
          label="name"
          :reduce="group => group.groupId"
          :options="groups"
          multiple
          @search="searchUser"
        />
      </b-input-group>
      <b-input-group
        class="input-group-merge tw-pb-3 mb-2 form-sidebar__add-user"
      >
        <b-input-group-prepend is-text>
          <feather-icon icon="AwardIcon" />
        </b-input-group-prepend>
        <prozess-select
          ref="roleId"
          v-model="resource.roleId"
          style="flex: 1"
          :placeholder="$t('Role')"
          :options="roles"
          label="name"
          :reduce="role => role.id"
          @search="searchUser"
        />
        <span
          v-if="$hasError('roleId')"
          class="invalid-feedback"
        >
          {{ $t($hasError('roleId')) }}
        </span>
      </b-input-group>

      <prozess-field-wrapper>
        <prozess-date-picker
          v-model="resource.birthDate"
          :is-birth-date="true"
          :locale="$i18n.locale"
          :placeholder="$t('Birth Date')"
        />
      </prozess-field-wrapper>
      <br />
      <b-form-checkbox
        v-model="resource.companyAdmin"
        switch
        inline
        @keyup.enter.native="save"
      >
        <span style="font-size: 1rem">
          {{ $t('Company Admin') }}
        </span>
      </b-form-checkbox>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { debounce } from 'lodash'
import { inviteUserSchema } from '@/schema/inviteUser'
import employeeService from '@/services/employee'
import groupService from '@/services/group'
import roleService from '@/services/role'
import prozessSelect from '@/@core/components/ProzessSelect.vue'

export default {
  components: {
    prozessSelect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      resource: {},
      selectUser: null,
      options: [],
      saving: false,
      loading: false,
      roles: [],
      groups: [],
      errors: [],
    }
  },
  computed: {
    saveIcon() {
      return this.resourceId ? '' : 'UserPlusIcon'
    },
    saveLabel() {
      return this.resourceId ? 'Save Changes' : 'Invite User'
    },
    formTitle() {
      return this.resourceId ? 'Edit User' : 'Invite New User'
    },
  },
  watch: {
    resourceId: {
      handler(id) {
        if (id) {
          return this.getOne(id)
        }
        return this.reset()
      },
      immediate: true,
    },
    visible: {
      handler(visible) {
        if (visible) {
          this.reset()
          this.getInitialData()
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleGroupInput() {
      this.$forceUpdate()
    },
    async getInitialData() {
      this.loading = true

      const res = await Promise.all([
        roleService.getAll(),
        groupService.getAll(),
      ])

      this.roles = res[0].data.pageItems
      this.groups = res[1].data.pageItems

      this.loading = false
    },
    reset() {
      this.errors = []
      this.resource = {}
      this.loading = false
      this.saving = false
    },
    getOne(id) {
      this.loading = true
      employeeService
        .getOne(id)
        .then(res => {
          this.resource = res.data
          this.resource.groupIds = res.data.groups.map(group => group.groupId)
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('close')
    },
    async save() {
      this.resource.companyAdmin = this.resource.companyAdmin ?? false
      this.errors = await this.yupValidate(inviteUserSchema(), this.resource)
      if (this.errors.length) return

      this.saving = true

      Object.keys(this.resource).forEach(key => {
        if (this.resource[key] !== null && this.resource[key].toString().trim() === '') {
          delete this.resource[key]
        }
      })

      if (!this.resource.groupIds) {
        this.resource.groupIds = []
      }

      this.createOrUpdate(this.resource)
        .then(res => {
          this.$emit('saved')
          this.close()
          if (res.data) {
            setTimeout(
              () => this.showWarning(
                res.data,
              ),
              500,
            )
          }
        })
        .catch(err => {
          this.errors = this.$lodash.get(err, 'response.data.errors', [])
        })
        .finally(() => {
          this.saving = false
        })
    },
    createOrUpdate(data) {
      return this.resourceId
        ? employeeService.update(this.resourceId, data)
        : employeeService.store({
          ...data,
          backendWillHandleTheProcess: true,
        })
    },
    onSearchUser(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUser(search, loading, this)
      }
    },
    searchUser: debounce(async (search, loading, vm) => {
      const res = await employeeService.getAll({
        // firstName: search,
      })
      loading(false)
    }, 1000),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/form-sidebar.scss';
</style>
